import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  BlogPostNavigation,
} from "@bluefin/components";
import { Header, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class BlogPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          className={"blog-page"}
          hero={
            <Background columns={1} stackable={true} className={"blog-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              >
                <Header as={"h1"}>Blog</Header>
              </Background.Image>
            </Background>
          }
        >
          <Grid stackable={true} className={"blog-page-container"}>
            <Grid.Column width={16}>
              <BlogPostNavigation
                title={""}
                description={""}
                featuredPostId={null}
                alwaysShowSearch={true}
                columns={4}
                posts={allFishermanBusinessBlogPost.nodes}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Blog" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessBlogPost {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
  }
`;
